<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Notifications"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            :color="filter === 'all' ? 'primary' : ''"
            @click="filter = 'all'"
            class="mx-2"
            >All</v-btn
          >
          <v-btn
            :color="filter === 'unread' ? 'primary' : ''"
            @click="filter = 'unread'"
            class="mx-2"
            >Unread</v-btn
          >
          <v-btn
            :color="filter === 'read' ? 'primary' : ''"
            @click="filter = 'read'"
            class="mx-2"
            >Read</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            v-if="filteredNotifications.some((elm) => !elm.is_read)"
            color="green"
            @click="markAllAsRead"
            >Mark All as Read</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="notification in filteredNotifications"
          :key="notification.id"
          cols="12"
        >
          <v-card>
            <v-card-title>
              <v-icon
                class="mx-2"
                :color="getNotificationColor(notification.notif_type)"
              >
                {{ getNotificationIcon(notification.notif_type) }}
              </v-icon>

              {{ notification.title }}

              <v-spacer></v-spacer>
              <!-- Displaying send via methods dynamically -->
              <div v-if="notification.sent_via">
                <v-chip-group>
                  <v-chip
                    v-for="via in JSON.parse(notification.sent_via)"
                    :key="via"
                  >
                    Sent via: {{ via }}
                  </v-chip>
                </v-chip-group>
              </div>

              <v-btn
                v-if="!notification.is_read"
                color="green"
                @click="markAsRead(notification.id)"
                >Mark as Read</v-btn
              >
            </v-card-title>

            <!-- Displaying sender and timestamps -->
            <v-card-subtitle class="mx-6">
              Sent at: {{ dateam(notification.created_at, true) }}
              <span v-if="notification.read_at">
                | Read at: {{ dateam(notification.read_at, true) }}
              </span>
              <span v-if="notification.sent_email_at">
                | Sent Email at: {{ notification.sent_email_at }}</span
              >
              <span v-if="notification.sent_sms_at">
                | Sent Whatsapp at: {{ notification.sent_sms_at }}</span
              >
            </v-card-subtitle>

            <v-card-text>
              {{ notification.message }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-btn v-if="hasMoreNotifications" @click="loadMoreNotifications">
        Load More
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import NOTIFICATIONS_PAGES from "@/graphql/Notifications/NOTIFICATIONS_PAGES.gql";
import MARK_AS_READ from "@/graphql/Notifications/MARK_AS_READ.gql";
import MARK_ALL_AS_READ from "@/graphql/Notifications/MARK_ALL_AS_READ.gql";
import { dateam } from "@/utils/functions.js";
export default {
  data: () => ({
    notifications: [],
    search: "",
    filter: "all", // 'all', 'unread', or 'read'
    page: 1,
    perPage: 10,
    hasMoreNotifications: true,
  }),
  computed: {
    dateam() {
      return dateam;
    },
    filteredNotifications() {
      let filtered = this.notifications;

      if (filtered) {
        if (this.filter === "unread") {
          filtered = filtered.filter((n) => !n.is_read);
        } else if (this.filter === "read") {
          filtered = filtered.filter((n) => n.is_read);
        }

        if (this.search) {
          filtered = filtered.filter((n) =>
            n.message.toLowerCase().includes(this.search.toLowerCase())
          );
        }
      }
      return filtered;
    },
    sent_via(item) {
      console.log(JSON.parse(item.sent_via));
      return true;
    },
  },
  methods: {
    async refresh() {
      let r = await this.$requette(NOTIFICATIONS_PAGES, {
        user_id: this.$store.state.me.id,
        page: this.page,
        first: this.perPage,
      });
      if (r) {
        this.notifications = r.data.notifications.data;

        const meta = r.data.notifications.data;
        this.hasMoreNotifications = meta.currentPage < meta.totalPages;
      }
    },
    loadMoreNotifications() {
      this.page += 1;
      this.refresh();
    },
    markAsRead(notificationId) {
      this.$apollo
        .mutate({
          mutation: MARK_AS_READ,
          variables: {
            id: notificationId,
          },
        })
        .then(() => {
          this.notifications = this.notifications.map((n) =>
            n.id === notificationId ? { ...n, is_read: true } : n
          );
          this.$store.dispatch("Changed", true);
        });
    },
    // Mark all notifications as read
    async markAllAsRead() {
      if (this.filteredNotifications.some((elm) => !elm.is_read)) {
        await this.$apollo
          .mutate({
            mutation: MARK_ALL_AS_READ,
            variables: { user_id: this.$store.state.me.id },
          })
          .then(() => {
            // Update UI after marking all as read
            this.notifications = this.notifications.map((n) => ({
              ...n,
              is_read: true,
            }));
            this.$store.dispatch("Changed", true);
          });
      }
    },
    getNotificationIcon(type) {
      switch (type) {
        case "info":
          return "mdi-information-outline";
        case "warning":
          return "mdi-alert-outline";
        case "critical":
          return "mdi-alert-circle-outline";
        default:
          return "mdi-bell-outline";
      }
    },
    getNotificationColor(type) {
      switch (type) {
        case "info":
          return "blue";
        case "warning":
          return "orange";
        case "critical":
          return "red";
        default:
          return "grey";
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
